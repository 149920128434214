@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  background-color: #12111d;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.animated-text {
  animation: moveLeft 5s reverse infinite;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #12111d;
}
::-webkit-scrollbar-thumb {
  background: #99dfaf;
  box-shadow: 0 0 5px 0 #12111d;
  border-radius: 10px;
}
